.data-type-selector {
    display: flex;
    flex-direction: column;
}

.data-type-selector-title {
    color: #253746;
    font-size: 14px;
    font-size: 0.875rem;
    height: 14px;
    letter-spacing: 0;
}

.data-type-selector-body {
    padding-top: 14px; 
    align-items: flex-start;
    display: flex;
}

.data-type-selector-button {
    border-radius: 25px;
    border: 2px solid white;
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    margin-right: 8px;
    padding: 2px;
    height: 35px;
    width: 35px;
}

.data-type-selector-button i {
    font-size: 16px;
    line-height: 35px;
}

.data-type-selectbox {
    margin-top: 10px;
    margin-bottom: 20px;
}

.default {
    background-color: white;
    color: #fc3c3c;
    cursor: pointer;
}
.default:hover {
    border-color: #fc3c3c;
}

.selected { 
    color: white;
    cursor: default;
    background-color: #fc3c3c;
    border-color: #fc3c3c;
    box-shadow: 0 2px 7px 0 rgba(249, 66, 58, 0.71);
}

.data-type-selector-line-break {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #d9d9d6;
    margin: 16px 0;
    margin: 1rem 0;
    padding: 0;
}

.datatype-icon {
    color: #717C7D;
    width: 40px;
    text-align: center;
}

.custom-option:hover .datatype-icon {
    color: #0058ff;
}