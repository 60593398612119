.upload-button-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
    height: 100%;
    width: 100%;
}
  
.upload-button-wrapper input[type=file] {
    cursor: pointer;
    left: 0;
    top: 0;
    opacity: 0;
    height: 100%;
    width: 100%;
    position: absolute;
}

.dropzone-custom {
    height: 100%;
    width: 100%;
}