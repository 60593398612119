.App {
    width: 80%;
    margin: 0 auto;
    height: 100%;
    min-width: 500px;
}

@media (min-width: 714px) {
  .App {
    width: 80%;
    margin: 0 auto 0; 
  }
}

html, body, #root {
    height: 100%;
    margin: 0px;
    padding: 0px;
    overflow: auto;
}

body {
    background-image: url('Waves.svg');
    background-color: #FBFBFB;
    background-position: 50% 180%;
    background-repeat: no-repeat;
    background-size: 100% auto;
}

.App-logo {
    height: 80px;
}

.App-header {
    padding: 0 0 30px;
    display: flex;
    flex-direction: column;
}

.welcome {
    color: #0058FF;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 23px;
    height: 29px;
    letter-spacing: 0;
    width: 333px;
}

.organization-name {
    color: #253746;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 60px;
    font-weight: 500;
    height: 77px;
    letter-spacing: 0.4px;
    width: 298px;
}

.main-message {
    color: #717C7D;
    font-family: 'Montserrat', sans-serif;
    font-size: 24px;
    font-weight: 300;
    height: 29px;
    padding-top: 15px;
    letter-spacing: 0;
    width: 357px;
}

.logo {
    background-image: url('logo.svg');
    background-size: cover;
    height: 16px;
    height: 1rem;
    width: 116.92px;
    width: 7.3075rem;
    margin-top: 40px;
    margin-bottom: 60px;
    display: block;
}

.loading-mask {
    padding-top: 15%;
    text-align: center;
}

.spinner-logo {
    height: 150px;
    width: 150px;
    text-align: center;
    animation-name: spin;
    animation-duration: 5000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@keyframes spin {
  from {
      transform:rotate(0deg);
  }
  to {
      transform:rotate(360deg);
  }
}
