.file-upload-dialog {
    background-color: #F6F6F6;
    border-radius: 5px;
    box-shadow: 0 0 12px 2px rgba(0, 0, 0, 0.17);
    display: flex;
    align-content: stretch;
    margin-bottom: 30px;
}


.upload-section {
    padding: 30px;
    background: white;
    flex: 1;
}

.status-display-section {
    padding: 30px 30px 30px 0;
    background: #F9F9F9;
    flex: 1;
    min-width: 0;
}

.add-button {
    cursor: pointer;
}

.add-button-icon {
    font-size: 2rem;
    color: #FB3636;
    display: inline-block;
    font-size: 40px;
    line-height: 50px;
    width: 50px;
    height: 50px;
    text-align: center;
    vertical-align: middle;
}

.instructions {
    color: #d8d8d8;
    font-size: 12px;
    font-size: 0.75rem;
    line-height: 16px;
    line-height: 1rem;
    margin-top: 15px;
}

.initial-add-button {
    background-color: #fc3c3c;
    border-radius: 21px;
    box-shadow: 0 2px 7px 0 #f9423a;
    color: white;
    font-size: 1rem;
    line-height: 3rem;    
    font-weight: 100;
    padding-left: 15px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    min-width: 180px;
    cursor: pointer;
}

.initial-add-button-icon {
    font-size: 0.75rem;
    color: white;
    border-radius: 1.125rem;
    border: 1px solid white;
    padding: 0.35rem;
    margin-right: 10px;
}

.pro-link {
    color: rgb(0,0,0);
    font-weight: 600;
}

.step-text {
    color: #253746;
    font-size: 20px;
    font-size: 1.25rem;
    font-weight: 300;
    height: 25px;
    letter-spacing: 0.13px;
}

.upgrade-verbiage {
    font-size: 12px;
    font-size: 0.75rem;
    line-height: 14px;
    line-height: 0.875rem;
}

textarea:focus, input:focus{
    outline: none;
}

.message-input-container {
    display: flex;
    flex-direction: column;
}

.message-input {
    box-shadow: none;
    border-color: #d9d9d6;
    border-width: 0 0 1px 0;
    border-style: solid;
    background: none;
    margin-top: 10px;
}

.message-textarea {
    height: 150px; 
    resize: none;
}

.divider {
    margin: 15px 0;
    border-top: 1px solid #979797;
}

.bottom-divider {
    border-top: 1px solid #B2B4B2;
}