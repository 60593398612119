.status-display {
    margin-left: 40px;
}

.status-display-title {
    font-size: 32px;
    font-size: 2rem;
    margin-bottom: 15px;
}

.cell {
    line-height: 28px;
    line-height: 1.75rem;
}

.status-icon {
    font-size: 12px;
    color: #669bff;
    padding-right: 5px;
    width: 30px;
    text-align: center;
}

.status-icon.complete {
    color: #198B67;
}

.status-icon.rejected {
    color: red;
}

.type-icon {
    font-size: 12px;
    padding-right: 3px;
    text-align: center;
    width: 40px;
}

.spinner-logo {
    height: 80px;
    width: 80px;
    text-align: center;
}

.react-grid-Row--even .react-grid-Cell {
    background-color: #fafafa;
}

.spinner {
    -webkit-animation: ld-spin 1s infinite;
    animation: ld-spin 1s infinite;
}

.file-list-header {
    color: #253746;
    font-size: 18px;
    font-size: 1.125rem;
    font-weight: 500;
    height: 23px;
    letter-spacing: 0;
}

.file-list-header span#upload-count {
    color: #8F8F8F;
    font-size: 12px;
    font-size: 0.75rem;
    font-style: italic;
    margin-left: 5px;
}

hr {
    border: 0;
    border-top: 1px solid #040036;
    margin: 2px 0 14px;
}

.file-list-empty-text {
    color: #8F8F8F;
    font-size: 12px;
    font-size: 0.75em;
    height: 21px;
    letter-spacing: 0;
    line-height: 18.2px;
}

.formatted-status {
    font-size: 12px;
    font-size: 0.75rem;
    line-height: 20px;
    line-height: 1.25rem;
    color: #253746;
    border-bottom: solid 1px #d9d9d6;
    padding: 5px 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.file-list-statuses {
    max-height: 384px;
    overflow-y: auto;
}

.file-list-statuses .formatted-status:first-child {
    padding-top: 0;
}

.status-filename {
    cursor: default;
    padding-right: 10px;
    flex: 2;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #1B4FA3;
    cursor: pointer;
}

.status-uploaddatetime {
    cursor: default;
    padding-right: 10px;
    flex: 2;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@-webkit-keyframes ld-spin {
    0% {
        transform: rotate(0);
        animation-timing-function: cubic-bezier(.55,.055,.675,.19);
    }
    50% {
        transform: rotate(180deg);
        animation-timing-function: cubic-bezier(.215,.61,.355,1);
    }
    100% {
        transform: rotate(360deg);
    }
}
@keyframes ld-spin {
    0% {
        transform: rotate(0);
        animation-timing-function: cubic-bezier(.55,.055,.675,.19);
    }
    50% {
        transform: rotate(180deg);
        animation-timing-function: cubic-bezier(.215,.61,.355,1);
    }
    100% {
        transform: rotate(360deg);
    }
}