.dd-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 2.5rem 0;
    background: rgba(73,121,221,0.8);
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
    height: 100%;
    z-index: 1000;
    overflow: auto;
}

.dd-takeoff-verbiage {
    font-size: 54px;
    font-size: 3.375rem;
    padding-bottom: 20px;
}

.dd-drop-verbiage {
    font-size: 18px;
    font-size: 1.125rem;
    font-weight: 200;
}
