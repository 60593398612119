@import url(https://fonts.googleapis.com/css?family=IBM+Plex+Sans:300,400,500,600,700);
@import url(https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600);
body {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-family: 'IBM Plex Sans', sans-serif;
}

.dd-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 2.5rem 0;
    background: rgba(73,121,221,0.8);
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
    height: 100%;
    z-index: 1000;
    overflow: auto;
}

.dd-takeoff-verbiage {
    font-size: 54px;
    font-size: 3.375rem;
    padding-bottom: 20px;
}

.dd-drop-verbiage {
    font-size: 18px;
    font-size: 1.125rem;
    font-weight: 200;
}

.data-type-selector {
    display: flex;
    flex-direction: column;
}

.data-type-selector-title {
    color: #253746;
    font-size: 14px;
    font-size: 0.875rem;
    height: 14px;
    letter-spacing: 0;
}

.data-type-selector-body {
    padding-top: 14px; 
    align-items: flex-start;
    display: flex;
}

.data-type-selector-button {
    border-radius: 25px;
    border: 2px solid white;
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    margin-right: 8px;
    padding: 2px;
    height: 35px;
    width: 35px;
}

.data-type-selector-button i {
    font-size: 16px;
    line-height: 35px;
}

.data-type-selectbox {
    margin-top: 10px;
    margin-bottom: 20px;
}

.default {
    background-color: white;
    color: #fc3c3c;
    cursor: pointer;
}
.default:hover {
    border-color: #fc3c3c;
}

.selected { 
    color: white;
    cursor: default;
    background-color: #fc3c3c;
    border-color: #fc3c3c;
    box-shadow: 0 2px 7px 0 rgba(249, 66, 58, 0.71);
}

.data-type-selector-line-break {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #d9d9d6;
    margin: 16px 0;
    margin: 1rem 0;
    padding: 0;
}

.datatype-icon {
    color: #717C7D;
    width: 40px;
    text-align: center;
}

.custom-option:hover .datatype-icon {
    color: #0058ff;
}
.file-confirmation-panel {
    margin: 20px 0 0 0;
    transition: all .5s ease-in-out;
    overflow: hidden;
}

.closed {
    height: 0;
}

.open {
    height: 150px;
}

.file-confirmation-body {
    font-size: 12px;
    font-size: 0.75rem;
    color: #717c7d;
    min-height: 150px;
    display: flex;
    flex-direction: column;
}

.file-confirmation-selected-name {
    border: 3px solid black;
    margin: 20px 0;
    padding: 2px 2px 2px 8px;
}

.file-confirmation-buttons {
    border-top: 1px solid #B2B4B2;
    display: flex;
    margin-top: 20px;
    align-items: center;
    padding: 15px 0 0 0;
    justify-content: space-between;
}

.type-label {
    font-style: oblique;
}

.progressbar .progress.error {
    height: 2px;
    -webkit-filter: blur(0.9px);
            filter: blur(0.9px);
    background-color: #d43831;
}

.progressbar .progress {
    height: 2px;
    -webkit-filter: blur(0.9px);
            filter: blur(0.9px);
    background-color: #004EA9;
}

.progressbar {
    height: 2px;
    background-color: #b2b4b2;
    width: 500px;
}

.import-button {
    border-radius: 18px;
    color: #AEBFBF;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    font-size: 0.875em;
    font-weight: 500;
    letter-spacing: 0.09px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    width: 100px;
}

.import-button.active {
    background-color: #253746;
    color: white;
    cursor: pointer;
}

.import-button.disabled {
    background-color: #EBEFEF;
    cursor: default;
}

.file-confirmation-workarea {
    flex: 1 1;
    cursor: pointer;
    line-height: 20px;
    line-height: 1.25rem;
}

.abort-button.disabled {
    color: #b2b4b2;
}

.abort-button.active {
    color: #669bff;
    cursor: pointer;
}

.selected-files-names {
    max-height: 250px;
    overflow-y: auto; 
}

.file-upload-message {
    background: #F9F9F9;
    border-radius: 6px 6px 6px 6px;
    border: 1px solid #EAE9E9;
    height: 118px;
    margin-top: 8px;
    margin-bottom: 20px;
}

.file-upload-drag-message {
    color: #253746;
    margin: 20px 0;
    font-size: 20px;
    font-size: 1.25em;
    font-weight: 300;
    letter-spacing: 0.13px;
    text-align: center;
}

.file-upload-browse-message {
    color: #8F8F8F;
    font-size: 16px;
    font-size: 1em;
    letter-spacing: 0;
    line-height: 18.2px;
    text-align: center;
}

.browse {
    color:#1B4FA3;
}

.selected-file-confirmation {
    padding-top: 30px;
    padding-left: 30px;
    display: flex;
}

.survey-icon {
    padding-right: 10px;
    color: #8F8F8F;
    font-size: 48px;
}

.cancel-icon {
    padding-right: 10px;
    color: #d43831;
    font-size: 48px;
}

.upload-name {
    color: #253746;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 16px;
    font-size: 1rem;
    height: 19px;
    letter-spacing: 0;
    line-height: 18.2px;
}

.upload-button-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
    height: 100%;
    width: 100%;
}
  
.upload-button-wrapper input[type=file] {
    cursor: pointer;
    left: 0;
    top: 0;
    opacity: 0;
    height: 100%;
    width: 100%;
    position: absolute;
}

.dropzone-custom {
    height: 100%;
    width: 100%;
}
.status-display {
    margin-left: 40px;
}

.status-display-title {
    font-size: 32px;
    font-size: 2rem;
    margin-bottom: 15px;
}

.cell {
    line-height: 28px;
    line-height: 1.75rem;
}

.status-icon {
    font-size: 12px;
    color: #669bff;
    padding-right: 5px;
    width: 30px;
    text-align: center;
}

.status-icon.complete {
    color: #198B67;
}

.status-icon.rejected {
    color: red;
}

.type-icon {
    font-size: 12px;
    padding-right: 3px;
    text-align: center;
    width: 40px;
}

.spinner-logo {
    height: 80px;
    width: 80px;
    text-align: center;
}

.react-grid-Row--even .react-grid-Cell {
    background-color: #fafafa;
}

.spinner {
    -webkit-animation: ld-spin 1s infinite;
    animation: ld-spin 1s infinite;
}

.file-list-header {
    color: #253746;
    font-size: 18px;
    font-size: 1.125rem;
    font-weight: 500;
    height: 23px;
    letter-spacing: 0;
}

.file-list-header span#upload-count {
    color: #8F8F8F;
    font-size: 12px;
    font-size: 0.75rem;
    font-style: italic;
    margin-left: 5px;
}

hr {
    border: 0;
    border-top: 1px solid #040036;
    margin: 2px 0 14px;
}

.file-list-empty-text {
    color: #8F8F8F;
    font-size: 12px;
    font-size: 0.75em;
    height: 21px;
    letter-spacing: 0;
    line-height: 18.2px;
}

.formatted-status {
    font-size: 12px;
    font-size: 0.75rem;
    line-height: 20px;
    line-height: 1.25rem;
    color: #253746;
    border-bottom: solid 1px #d9d9d6;
    padding: 5px 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.file-list-statuses {
    max-height: 384px;
    overflow-y: auto;
}

.file-list-statuses .formatted-status:first-child {
    padding-top: 0;
}

.status-filename {
    cursor: default;
    padding-right: 10px;
    flex: 2 1;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #1B4FA3;
    cursor: pointer;
}

.status-uploaddatetime {
    cursor: default;
    padding-right: 10px;
    flex: 2 1;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@-webkit-keyframes ld-spin {
    0% {
        -webkit-transform: rotate(0);
                transform: rotate(0);
        -webkit-animation-timing-function: cubic-bezier(.55,.055,.675,.19);
                animation-timing-function: cubic-bezier(.55,.055,.675,.19);
    }
    50% {
        -webkit-transform: rotate(180deg);
                transform: rotate(180deg);
        -webkit-animation-timing-function: cubic-bezier(.215,.61,.355,1);
                animation-timing-function: cubic-bezier(.215,.61,.355,1);
    }
    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}
@keyframes ld-spin {
    0% {
        -webkit-transform: rotate(0);
                transform: rotate(0);
        -webkit-animation-timing-function: cubic-bezier(.55,.055,.675,.19);
                animation-timing-function: cubic-bezier(.55,.055,.675,.19);
    }
    50% {
        -webkit-transform: rotate(180deg);
                transform: rotate(180deg);
        -webkit-animation-timing-function: cubic-bezier(.215,.61,.355,1);
                animation-timing-function: cubic-bezier(.215,.61,.355,1);
    }
    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}
.file-upload-dialog {
    background-color: #F6F6F6;
    border-radius: 5px;
    box-shadow: 0 0 12px 2px rgba(0, 0, 0, 0.17);
    display: flex;
    align-content: stretch;
    margin-bottom: 30px;
}


.upload-section {
    padding: 30px;
    background: white;
    flex: 1 1;
}

.status-display-section {
    padding: 30px 30px 30px 0;
    background: #F9F9F9;
    flex: 1 1;
    min-width: 0;
}

.add-button {
    cursor: pointer;
}

.add-button-icon {
    font-size: 2rem;
    color: #FB3636;
    display: inline-block;
    font-size: 40px;
    line-height: 50px;
    width: 50px;
    height: 50px;
    text-align: center;
    vertical-align: middle;
}

.instructions {
    color: #d8d8d8;
    font-size: 12px;
    font-size: 0.75rem;
    line-height: 16px;
    line-height: 1rem;
    margin-top: 15px;
}

.initial-add-button {
    background-color: #fc3c3c;
    border-radius: 21px;
    box-shadow: 0 2px 7px 0 #f9423a;
    color: white;
    font-size: 1rem;
    line-height: 3rem;    
    font-weight: 100;
    padding-left: 15px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    min-width: 180px;
    cursor: pointer;
}

.initial-add-button-icon {
    font-size: 0.75rem;
    color: white;
    border-radius: 1.125rem;
    border: 1px solid white;
    padding: 0.35rem;
    margin-right: 10px;
}

.pro-link {
    color: rgb(0,0,0);
    font-weight: 600;
}

.step-text {
    color: #253746;
    font-size: 20px;
    font-size: 1.25rem;
    font-weight: 300;
    height: 25px;
    letter-spacing: 0.13px;
}

.upgrade-verbiage {
    font-size: 12px;
    font-size: 0.75rem;
    line-height: 14px;
    line-height: 0.875rem;
}

textarea:focus, input:focus{
    outline: none;
}

.message-input-container {
    display: flex;
    flex-direction: column;
}

.message-input {
    box-shadow: none;
    border-color: #d9d9d6;
    border-width: 0 0 1px 0;
    border-style: solid;
    background: none;
    margin-top: 10px;
}

.message-textarea {
    height: 150px; 
    resize: none;
}

.divider {
    margin: 15px 0;
    border-top: 1px solid #979797;
}

.bottom-divider {
    border-top: 1px solid #B2B4B2;
}
.top-bar {
    text-align: right;
    margin-right: 8%;
    margin-top: 5%;
}
.App {
    width: 80%;
    margin: 0 auto;
    height: 100%;
    min-width: 500px;
}

@media (min-width: 714px) {
  .App {
    width: 80%;
    margin: 0 auto 0; 
  }
}

html, body, #root {
    height: 100%;
    margin: 0px;
    padding: 0px;
    overflow: auto;
}

body {
    background-image: url(/static/media/Waves.6e719a4.svg);
    background-color: #FBFBFB;
    background-position: 50% 180%;
    background-repeat: no-repeat;
    background-size: 100% auto;
}

.App-logo {
    height: 80px;
}

.App-header {
    padding: 0 0 30px;
    display: flex;
    flex-direction: column;
}

.welcome {
    color: #0058FF;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 23px;
    height: 29px;
    letter-spacing: 0;
    width: 333px;
}

.organization-name {
    color: #253746;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 60px;
    font-weight: 500;
    height: 77px;
    letter-spacing: 0.4px;
    width: 298px;
}

.main-message {
    color: #717C7D;
    font-family: 'Montserrat', sans-serif;
    font-size: 24px;
    font-weight: 300;
    height: 29px;
    padding-top: 15px;
    letter-spacing: 0;
    width: 357px;
}

.logo {
    background-image: url(/static/media/logo.6e719a4.svg);
    background-size: cover;
    height: 16px;
    height: 1rem;
    width: 116.92px;
    width: 7.3075rem;
    margin-top: 40px;
    margin-bottom: 60px;
    display: block;
}

.loading-mask {
    padding-top: 15%;
    text-align: center;
}

.spinner-logo {
    height: 150px;
    width: 150px;
    text-align: center;
    -webkit-animation-name: spin;
            animation-name: spin;
    -webkit-animation-duration: 5000ms;
            animation-duration: 5000ms;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
}

@-webkit-keyframes spin {
  from {
      -webkit-transform:rotate(0deg);
              transform:rotate(0deg);
  }
  to {
      -webkit-transform:rotate(360deg);
              transform:rotate(360deg);
  }
}

@keyframes spin {
  from {
      -webkit-transform:rotate(0deg);
              transform:rotate(0deg);
  }
  to {
      -webkit-transform:rotate(360deg);
              transform:rotate(360deg);
  }
}

