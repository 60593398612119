.file-confirmation-panel {
    margin: 20px 0 0 0;
    transition: all .5s ease-in-out;
    overflow: hidden;
}

.closed {
    height: 0;
}

.open {
    height: 150px;
}

.file-confirmation-body {
    font-size: 12px;
    font-size: 0.75rem;
    color: #717c7d;
    min-height: 150px;
    display: flex;
    flex-direction: column;
}

.file-confirmation-selected-name {
    border: 3px solid black;
    margin: 20px 0;
    padding: 2px 2px 2px 8px;
}

.file-confirmation-buttons {
    border-top: 1px solid #B2B4B2;
    display: flex;
    margin-top: 20px;
    align-items: center;
    padding: 15px 0 0 0;
    justify-content: space-between;
}

.type-label {
    font-style: oblique;
}

.progressbar .progress.error {
    height: 2px;
    filter: blur(0.9px);
    background-color: #d43831;
}

.progressbar .progress {
    height: 2px;
    filter: blur(0.9px);
    background-color: #004EA9;
}

.progressbar {
    height: 2px;
    background-color: #b2b4b2;
    width: 500px;
}

.import-button {
    border-radius: 18px;
    color: #AEBFBF;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    font-size: 0.875em;
    font-weight: 500;
    letter-spacing: 0.09px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    width: 100px;
}

.import-button.active {
    background-color: #253746;
    color: white;
    cursor: pointer;
}

.import-button.disabled {
    background-color: #EBEFEF;
    cursor: default;
}

.file-confirmation-workarea {
    flex: 1;
    cursor: pointer;
    line-height: 20px;
    line-height: 1.25rem;
}

.abort-button.disabled {
    color: #b2b4b2;
}

.abort-button.active {
    color: #669bff;
    cursor: pointer;
}

.selected-files-names {
    max-height: 250px;
    overflow-y: auto; 
}

.file-upload-message {
    background: #F9F9F9;
    border-radius: 6px 6px 6px 6px;
    border: 1px solid #EAE9E9;
    height: 118px;
    margin-top: 8px;
    margin-bottom: 20px;
}

.file-upload-drag-message {
    color: #253746;
    margin: 20px 0;
    font-size: 20px;
    font-size: 1.25em;
    font-weight: 300;
    letter-spacing: 0.13px;
    text-align: center;
}

.file-upload-browse-message {
    color: #8F8F8F;
    font-size: 16px;
    font-size: 1em;
    letter-spacing: 0;
    line-height: 18.2px;
    text-align: center;
}

.browse {
    color:#1B4FA3;
}

.selected-file-confirmation {
    padding-top: 30px;
    padding-left: 30px;
    display: flex;
}

.survey-icon {
    padding-right: 10px;
    color: #8F8F8F;
    font-size: 48px;
}

.cancel-icon {
    padding-right: 10px;
    color: #d43831;
    font-size: 48px;
}

.upload-name {
    color: #253746;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 16px;
    font-size: 1rem;
    height: 19px;
    letter-spacing: 0;
    line-height: 18.2px;
}
